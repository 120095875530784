import React, {FunctionComponent} from "react";
import Splash from "../../components/splash";

const ComingSoonPage:FunctionComponent = () => {
    return (
        <React.Fragment>
            <Splash 
                title="Website Coming Soon"
                subtitle="Why is this on the same line?"
                image="icon.png"
            />
        </React.Fragment>
    );
}

export default ComingSoonPage;