import {FunctionComponent} from "react";
import {History} from "history";
import { Router } from "react-router";

import Routes from "./routes/routes"

interface IProps {
  history: History
}

const App:FunctionComponent<IProps> = ({history}) => {
  return (
    <div className="App">
      <Router history={history} >
        <Routes /> 
      </Router>
    </div>
  );
}

export default App;
