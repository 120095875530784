import React, {FunctionComponent} from "react";
import { Route } from "react-router";
/* React Page components */
import Home from "../pages/home";
import ComingSoon from "../pages/coming-soon";

/**
 * Props 
 */
interface IProps {}

/**
 * Add routes for application
 */
const Routes: FunctionComponent<IProps> = () => {
    return (
        <React.Fragment>
            <Route path="/" exact component={ComingSoon} />
        </React.Fragment>
    )
};

export default Routes;
